import React from "react"
import { Link } from "gatsby"
import validate from "validate.js"
import Layout from "../components/layout"
import Particles from "react-particles-js"
import particleOptions from "../assets/particles.json"
import axios from "axios"

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 300,
    },
  },
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 120,
    },
  },
  message: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 500,
    },
  },
}

export default () => {
  const [submitted, setSubmited] = React.useState("notsubmitted")
  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    errors: {},
  })

  React.useEffect(() => {
    const errors = validate(formState.values, schema)

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }))
  }, [formState.values])

  const handleChange = (event) => {
    event.persist()

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
    }))
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (formState.isValid) {
      axios
        .post(
          "https://hooks.zapier.com/hooks/catch/10583025/b2l941g/silent/",
          formState.values,
          {
            headers: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
          }
        )
        .then(() => {
          setSubmited("submitted")
        })
        .catch(() => {
          setSubmited("failed")
        })
    }

    setFormState(() => ({
      isValid: false,
      values: {},
      errors: {},
    }))
  }

  return (
    <Layout>
      <Particles className="particles" params={particleOptions} />
      <div className="outerContainer">
        <div className="mainContainer">
          <h1 className="headline">Contact me!</h1>
          <p className="underline">
            Inspired idea? Creating something special?
          </p>
          <form
            className="mainContainerForm"
            name="contact-form"
            method="post"
            onSubmit={handleSubmit}
          >
            <label htmlFor="name">
              Name <span class="star">*</span>
            </label>
            <input
              placeholder="Bob Morane"
              name="name"
              onChange={handleChange}
              type="text"
              value={formState.values.name || ""}
              required
            />
            <label htmlFor="email">
              Email <span class="star">*</span>
            </label>
            <input
              placeholder="bob@example.com"
              name="email"
              onChange={handleChange}
              type="email"
              value={formState.values.email || ""}
              required
            />
            <label htmlFor="message">
              Message <span class="star">*</span>
            </label>
            <textarea
              placeholder="Your message here..."
              name="message"
              onChange={handleChange}
              type="text"
              value={formState.values.message || ""}
              required
            />
            <div className="submitButtonContainer">
              {submitted === "notsubmitted" && (
                <button className="submitButton" type="submit">
                  Send
                </button>
              )}
              {submitted === "submitted" && (
                <>
                  <button disabled className="submitButtonValid" type="submit">
                    Sent
                  </button>
                  <p>
                    Thank you for your interest, we will get back to you as soon
                    as possible.
                  </p>
                </>
              )}
              {submitted === "failed" && (
                <>
                  <button
                    disabled
                    className="submitButtonNotValid"
                    type="submit"
                  >
                    Not sent
                  </button>
                  <p>An error occured. Please try again later.</p>
                </>
              )}
            </div>
          </form>
          <Link className="homeLink" to="/">
            Back to home
          </Link>
        </div>
      </div>
    </Layout>
  )
}
